const $ = require('jquery')
const { getCookie } = require('./helpers/helpers')

const axios = require('axios')

const API_HEADERS = {}
const sessionIdCookie = getCookie('sso-sessionId')
const token = window.localStorage.getItem('Token')

function parseItemFromSessionStorage(item) {
  try {
    return JSON.parse(window.localStorage.getItem(item))
  } catch (e) {
    return {}
  }
}

$(function onDocumentReady() {
  function loadUsersData() {
    if (sessionIdCookie) {
      let account = parseItemFromSessionStorage('Account')
      let session = parseItemFromSessionStorage('Session')
      let entitlements = parseItemFromSessionStorage('Entitlements') || []
      if (session && session.accountReference) {
        $('#loggedOut').addClass('hide')
        $('#loggedIn').removeClass('hide')
      }
      if (account && account.accountReference) {
        $('#withoutAccount').addClass('hide')
        $('#withAccount').removeClass('hide')
        $('#withAccount').html(account.email)
      }
      if (account && account.accountReference) {
        window.userData = {
          id: account.email || account.accountReference,
          status: entitlements.length > 0 ? 'abonnent' : 'loggedin',
          products: [],
          entitlements,
        }
      } else {
        window.userData = {
          id: undefined,
          status: 'anonymous',
          products: [],
          entitlements: [],
        }
      }
    }
  }
  if (sessionIdCookie && token !== sessionIdCookie) {
    API_HEADERS['X-SessionId'] = sessionIdCookie
    axios
      .post(`/api/user`, {}, { headers: API_HEADERS })
      .then(response => {
        const { session, account, entitlements } = response.data
        window.localStorage.setItem('Account', JSON.stringify(account))
        window.localStorage.setItem('Session', JSON.stringify(session))
        window.localStorage.setItem(
          'Entitlements',
          JSON.stringify(entitlements || []),
        )
        window.localStorage.setItem('Token', sessionIdCookie)
        loadUsersData()
      })
      .catch(e => {
        console.log(e)
        window.localStorage.setItem('Token', sessionIdCookie)
      })
  }
  loadUsersData()
})
